<template>
  <!--begin::Basic info-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 bg-default">
      <!--begin::Card title-->
      <div class="card-title m-0 w-100 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center justify-content-start" @click="backMenu">
          <i class="bi bi-arrow-left h3 me-4 back-menu text-white"></i>
          <h3 class="fw-bolder m-0 text-white">Detail Kunjungan</h3>
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body border-top p-9 strip-bg">
        <div class="d-flex flex-wrap py-5">
          <div class="flex-equal me-5">
            <div class="mb-10">
              <h5 class="mb-1">Informasi:</h5>
              <div class="d-flex flex-wrap py-5">
                <div class="flex-equal me-5">
                  <table class="table fs-6 fw-semobold gs-0 gy-2 gx-2 m-0">
                    <tr>
                      <td class="text-gray-400">Lokasi:</td>
                      <td class="text-gray-800">
                        {{ detail.kolektif.address }},
                        {{ detail.kolektif.territory_name }},
                        {{ detail.kolektif.kecamatan_name }} -
                        {{ detail.kolektif.kabupaten_name }},
                        {{ detail.kolektif.provinsi_name }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Deskripsi:</td>
                      <td class="text-gray-800">
                        {{ detail.kolektif.deskripsi }}
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td class="text-gray-400">Waktu Terkirim:</td>
                      <td class="text-gray-800">
                        {{ detail.kolektif.createdAt }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-gray-400">Titik lokasi:</td>
                      <td class="text-gray-800"
                        @click="maps({ latitude: detail.kolektif.latitude, longitude: detail.kolektif.longitude })">
                        {{ detail.kolektif.latitude }},
                        {{ detail.kolektif.longitude }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-equal text-right">
            <div class="mb-10">
              <h5 class="mb-1">Relawan:</h5>
              <div class="d-flex flex-wrap py-5">
                <div class="flex-equal me-5">
                  <table class="table fs-6 fw-semobold gs-0 gy-2 gx-2 m-0">
                    <tr>
                      <td class="text-gray-400 min-w-175px w-175px">
                        Nama Relawan:
                      </td>
                      <td class="text-gray-800">
                        {{ detail.created_by.name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="text-gray-400">Terdaftar:</td>
                      <td class="text-gray-800">
                        {{ detail.created_by.createdAt }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="flex-equal">
            <div class="mb-10">
              <h5 class="mb-5">Foto Rombongan 1:</h5>
              <img class="img-fluid" :src="detail.kolektif.image_first" alt="foto kegiatan" style="max-width: 300px" />
            </div>
          </div>
          <div class="flex-equal">
            <div class="mb-10">
              <h5 class="mb-5">Foto Rombongan 2:</h5>
              <img class="img-fluid" :src="detail.kolektif.image_second" alt="foto kegiatan" style="max-width: 300px" />
            </div>
          </div>
          <div class="flex-equal">
            <div class="mb-10">
              <h5 class="mb-5">Foto Rombongan 3:</h5>
              <img class="img-fluid" :src="detail.kolektif.image_third" alt="foto kegiatan" style="max-width: 300px" />
            </div>
          </div>
        </div>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "Kunjungan",
  props: ["id"],
  setup(props) {
    setCurrentPageTitle("Kunjungan");

    const store = useStore();
    const router = useRouter();

    store.dispatch("getDetailKolektifDashboard", props.id);

    const detail = computed(() => store.state.KolektifDashboardModule.detail);

    const maps = (data) => {
      window.open(
        "https://maps.google.com/?q=" + data.latitude + "," + data.longitude,
        "_blank"
      );
    };

    const backMenu = () => {
      router.push({ name: "kolektif" });
    };

    return {
      maps,
      backMenu,
      detail,
    };
  },
});
</script>
